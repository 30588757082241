@font-face {
  font-family: "Lab Grotesque";
  src: url("https://assets.poplar.studio/platform/typefaces/LabGrotesque-Thin.woff2")
      format("woff2"),
    url("https://assets.poplar.studio/platform/typefaces/LabGrotesque-Thin.woff")
      format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Lab Grotesque";
  src: url("https://assets.poplar.studio/platform/typefaces/LabGrotesque-Medium.woff2")
      format("woff2"),
    url("https://assets.poplar.studio/platform/typefaces/LabGrotesque-Medium.woff")
      format("woff");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100vw;
  height: 100vh;
}

.App {
  width: 100vw;
  height: 100vh;
}

canvas {
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  padding: 0px;
  margin: 0px;
  text-align: center;
  font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

select {
  font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.7);
  width: 40%;
}
