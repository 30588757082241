.ui {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 20px;
  padding: 10px;
  width: 300px;
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.7);
}

.top-left {
  top: 0px;
  left: 0px;
}

.top-right {
  top: 0px;
  right: 0px;
}

.ui-text {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.more-info-button {
  border-radius: 100%;
  border: solid 1px rgba(0, 0, 0, 0.7);
  width: 20px;
  height: 20px;
}

.more-info {
  position: relative;
  top: 0px;
  left: -200px;
  word-wrap: break-word;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px;
  width: 200px;
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.7);
}

.ui-button {
  width: 100%;
  display: flex;
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.7);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  margin: 5px 0px;
  text-align: center;
  justify-content: center;
}

.ui-boolean {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
